import React, {useEffect, useRef} from 'react'

export default function Card({
                                 image = <img alt={"Error"}/>,
                                 heading = '',
                                 className = '',
                                 description = '',
                                 style = {},
                                 parentCls = ''
                             }) {
    const desc = useRef(null);
    const about_wrapper = useRef(null);
    useEffect(() => {
        function handleMouseEnter() {
            if (window.innerWidth <= 660) return;
            const paddingTop = getComputedStyle(wrapper).paddingTop.replace("px", '');
            const paddingBottom = getComputedStyle(wrapper).paddingTop.replace("px", '');
            const paddingDiff = paddingTop - paddingBottom;
            const textHeight = desc.current.offsetHeight - 80;
            const height = wrapper.offsetHeight + textHeight + paddingDiff;
            wrapper.style.height = height + "px";
            wrapper.style.paddingBottom = getComputedStyle(wrapper).paddingTop;
            wrapper.style.position = "absolute";
            wrapper.style.zIndex = "20";
        }

        function transitionEndHandler() {
            wrapper.style.removeProperty('position');
            wrapper.style.removeProperty('z-index');
            wrapper.removeEventListener('transitionend', transitionEndHandler);
        }

        function handleMouseLeave() {
            wrapper.style.zIndex = "18";
            wrapper.style.removeProperty('height');
            wrapper.style.removeProperty('padding-bottom');
            wrapper.addEventListener('transitionend', transitionEndHandler);
        }

        const wrapper = about_wrapper.current;
        if (wrapper.classList.contains("no-animation")) {
            return;
        }
        wrapper.addEventListener("mouseenter", handleMouseEnter);
        wrapper.addEventListener("mouseleave", handleMouseLeave)

        return () => {
            wrapper?.removeEventListener("mouseenter", handleMouseEnter);
            wrapper?.removeEventListener("mouseleave", handleMouseLeave);
        }
    }, [])
    return (
        <div className={`relative ${parentCls}`}>
            <div ref={about_wrapper}
                 className={`${className}`}
                 style={style}
            >
                <div
                    className="flex flex-col items-start justify-start p-0.5"
                >
                    {image}
                </div>
                <div>
                    <h3 className={"sub-heading capitalize font-semibold"}>
                        {heading}
                    </h3>
                </div>
                <div className={"description para-lg animate-300 overflow-hidden"}>
                    <span ref={desc}>
                        {description}
                    </span>
                </div>
            </div>
        </div>
    )
}
