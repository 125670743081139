import React from 'react'

export default function ChevronAngle({className}) {
    return (
        <svg className={className} width="62" height="33" viewBox="0 0 62 33" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M29.5874 1.07442L0.585047 29.1892C-0.195016 29.9454 -0.195016 31.1767 0.585047 31.9329C1.36511 32.689 2.63521 32.689 3.41528 31.9329L30.9975 5.185L58.5897 31.9232C59.3698 32.6794 60.6399 32.6794 61.4199 31.9232C61.8 31.5451 62 31.0506 62 30.5562C62 30.0618 61.8 29.5673 61.41 29.1892L32.4076 1.07442C31.6275 0.308529 30.3674 0.308529 29.5874 1.07442Z"
                fill="currentColor"/>
        </svg>

    )
}
