import React from 'react'

export default function Quotes() {
    return (
        <svg width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_313_2010)">
                <path fillRule="evenodd" clipRule="evenodd" d="M2 21.9722L2.00098 15.9722C2.00098 15.4202 2.44798 14.9722 3.00098 14.9722L10 14.9722C10.553 14.9722 11 15.4202 11 15.9722L11 21.9722C11 22.5242 10.553 22.9722 10 22.9722L3 22.9722C2.447 22.9722 2 22.5242 2 21.9722ZM11 24.9722C12 24.9722 13 23.9722 13 22.9722L13 14.9722C13 13.9722 12 12.9722 11 12.9722L2 12.9722C2 6.90117 5.25398 3.58716 11.652 3.11316C12.248 3.06816 12.708 2.57322 12.708 1.97522C12.708 1.42022 12.258 0.970213 11.702 0.970213C11.67 0.970213 11.637 0.971119 11.605 0.97412C3.86798 1.72412 1.68044e-06 5.75017 1.04907e-06 12.9722L1.74846e-07 22.9722C8.74228e-08 23.9722 1 24.9722 2 24.9722L11 24.9722ZM17 15.9722C17 15.4202 17.447 14.9722 18 14.9722L25 14.9722C25.553 14.9722 26 15.4202 26 15.9722L26 21.9722C26 22.5242 25.553 22.9722 25 22.9722L18 22.9722C17.447 22.9722 17 22.5242 17 21.9722L17 15.9722ZM26 24.9722C27 24.9722 28 23.9722 28 22.9722L28 14.9312C28 13.9722 27 12.9722 26 12.9722L17 12.9722C17 6.90117 20.546 3.58716 26.944 3.11316C27.539 3.06816 28 2.57322 28 1.97522C28 1.42022 27.55 0.970215 26.994 0.970215C26.962 0.970215 26.93 0.971121 26.896 0.974121C19.16 1.72412 15 5.74663 15 12.9686L15 22.9722C15 23.9722 16 24.9722 17 24.9722L26 24.9722Z" fill="currentColor"/>
            </g>
            <defs>
                <clipPath id="clip0_313_2010">
                    <rect width="28" height="24.002" fill="white" transform="translate(28 24.9722) rotate(-180)"/>
                </clipPath>
            </defs>
        </svg>

    )
}
