import React from 'react'

export default function Hamburger(props) {
    return (
        <svg className={`hamburger hamburger3 ${props.className}`} viewBox="0 0 80 45" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path className="line top"
                  d="M59.0799 2H19.0799C7.98771 2 30.9638 15.4967 15.6596 14.9568C14.6971 12.8674 13.4375 11.6738 11.1139 11.6738C8.79028 11.6738 6 14.2966 6 18.7449C6 23.1932 8.08061 25.7528 11.5558 25.7528C13.9578 25.7528 14.5235 24.4689 15.7227 22.4698C17.9321 22.7437 19.754 24.1123 21.5799 22.2173V9.2116V25.5H29.2335"
                  fill="currentColor" />
            <path className="line middle"
                  d="M59.0802 19H19.0802C13.394 19 10.5459 24.3735 10.5459 30.5511C10.5459 37.7388 14.0451 41.4734 23.6779 41.4734C34.6997 41.4734 30.7007 25.7 39.209 25.7C42.4771 25.7 44.386 23.5406 44.386 19C44.386 14.4594 42.6196 11.6647 39.2982 11.6739C35.9767 11.6831 33.5269 14.4635 33.5269 19C33.5269 23.5365 36.0059 25.8053 39.2982 25.7"
                  fill="currentColor" />
            <path className="line bottom"
                  d="M59.0797 36H19.0797C19.0797 36 15.3991 36.737 15.419 32.4821C15.4444 27.0665 18.8107 22.125 26.4012 22.125C30.4496 22.125 44.2904 22.3036 49.8833 22.3036C49.8833 24.8672 52.3345 25.7072 54.5262 25.6964C56.7178 25.6856 58.9001 24.3266 58.9012 22.3036C58.9023 20.2805 56.9768 19.7144 54.3476 18.1964C51.7184 16.6785 50.1512 16.3968 50.1512 14.5357C50.1512 12.6745 52.5934 11.4169 54.3476 11.5C56.1018 11.5831 58.7226 12.3908 58.7226 14.625C61.3512 14.625 64.8833 14.8928 64.8833 14.8928L64.7047 11.9464H74.8833H64.7047V18.6428H73.6333H64.7047V25.7857H74.8833H64.7047"
                  fill="currentColor" />
        </svg >
    )
}
