import React from "react";

function Calendar() {
  return (
    <div>
      <iframe
        src="https://api.leadconnectorhq.com/widget/booking/iXy9r3yT0B0DRuYGk5nS"
        style={{ width: '100%', border: 'none', height: '900px', overflow: 'hidden' }}
        scrolling="no"
        id="msgsndr-calendar"
        title="Booking Calendar" // Adding a title for accessibility
      ></iframe>
    </div>
  );
}

export default Calendar;
