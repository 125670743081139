import React, { useState } from 'react'
import Icon from "../../../assets/icons/Icon";
import { useFormik } from "formik";
import * as Yup from "yup"
import Images from "../../../assets/images/Images";

export default function Contact() {
    const schema = Yup.object({
        name: Yup.string().min(2).max(20).required("Please enter your name."),
        email: Yup.string().email().required("Please enter your email."),
        subject: Yup.string().min(10).required("Subject is required"),
        message: Yup.string().min(5).required("Message is required")
    });

    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleFormSubmission = (val) => {
        const URL = `https://script.google.com/macros/s/AKfycbyjKNMtxcIwAXd05Dx2deymabngNvCNMLuGNtAo9Bya7aQvcYepIEMas8GXhhDFNK16/exec`;
        fetch(URL, { method: "post", body: JSON.stringify(val, ['name', 'email', 'subject', 'message']) })
            .then(() => {
                formik.resetForm();
                setFormSubmitted(x => !x);
            })
            .catch(() => { });
    }

    const initialValues = {
        name: "",
        email: "",
        subject: "",
        message: ""
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: schema,
        onSubmit: handleFormSubmission
    });

    const { handleSubmit, values, isSubmitting, errors, touched, handleBlur, handleChange } = formik;
    const randStr = () => Math.random().toString(32).substr(2);

    const contact = {
        phone: "+92 336 650 2064",
        email: "info@xortlogix.com",
        address: "VIP, Street number 2, Ghouri Town, VIP Block, Islamabad Ghouri Town, Block, Islamabad",
        social: {
            facebook: {
                icon: "facebook",
                link: "https://web.facebook.com/profile.php?id=61555705217068"
            },
            instagram: {
                icon: "instagram",
                link: "https://www.instagram.com/xortlogix/"
            },
            twitter: {
                icon: "twitter",
                link: "https://twitter.com/XortLogix"
            },
            linkedIn: {
                icon: "linkedIn",
                link: "https://www.linkedin.com/company/hs-funnels/?viewAsMember=true"
            },
            whatsapp: {
                icon: "whatsapp",
                link: "https://wa.me/923366502064",
                target: "_blank"
            }
        }
    }

    const inputCls = "px-7 py-4 border border-light-grey-900 bg-off-white-900 rounded-full w-full outline-0 focus:border-black/70";

    return (
        <div className={"grid grid-cols-6 gap-10 lg:gap-0"}>
            {/* Contact Details */}
            <div className={"col-span-6 lg:col-span-2 bg-white py-16 px-6 sm:px-8 md:px-10 rounded-3xl"}>
                <div className={"flex flex-col gap-10"}>
                    <h2 className={"heading"}>
                        <a rel={"noreferrer noopener nofollow"} href={"tel:" + encodeURIComponent(contact.phone)}
                            className={"link w-max"}>{contact.phone}</a>
                    </h2>

                    <div className={"pt-10 flex flex-col gap-7 pl-5 border-t border-light-grey-900"}>
                        <h3 className={"sub-heading"}>Address</h3>
                        <span>{contact.address}</span>
                    </div>

                    <div className={"pt-10 flex flex-col gap-7 pl-5 border-t border-light-grey-900"}>
                        <h3 className={"sub-heading"}>Email</h3>
                        <a rel={"noreferrer noopener nofollow"} className={"link w-max"}
                            href={"mailto:" + encodeURIComponent(contact.email)}>{contact.email}</a>
                    </div>
                    <div className={"pt-10 flex gap-2.5 flex-wrap border-t border-light-grey-900"}>
                        {Object.keys(contact.social).map(social => {
                            return (
                                <a target={contact.social[social].target} rel={"noreferrer noopener nofollow"}
                                    href={contact.social[social].link} key={randStr()}
                                    className={"flex justify-center items-center w-14 h-14 rounded-full bg-off-white-900 text-black"}>
                                    <Icon name={contact.social[social].icon} />
                                </a>
                            )
                        })}
                    </div>
                </div>
            </div>
            {/* Contact Form */}
            <div className={"col-span-6 lg:col-span-4 lg:pl-5"}>
                <div className={"bg-white rounded-3xl flex flex-col gap-5 py-14 px-6 sm:px-8 md:px-14 h-full"}>
                    <iframe
                        src="https://api.leadconnectorhq.com/widget/form/V8lK1Js3RwgQwKoJryVT"
                        style={{ width: "100%", height: "600px", border: "none", borderRadius: "3px" }}
                        title="XortLogix"
                    />
                </div>
            </div>
        </div>
    )
}
