import './App.css';
import { GlobalContextProvider } from "./components/context/GlobalContext";
import { useEffect, useState } from "react";
import Navbar from "./components/assets/global/Navbar";
import { Outlet } from "react-router-dom";
import Footer from "./components/assets/global/Footer";
import ScrollToTop from "./components/assets/global/ScrollToTop";
import Preloader from "./components/assets/global/Preloader";
import Scrollpage from "./components/assets/Scrollpage"
async function loaderHandler() {
    let svg = document.getElementById("svg");
    let loaderWrapHeading = document.querySelector(".loader-wrap-heading");
    let loaderWrap = document.querySelector(".loader-wrap");
    let layoutWrapper = document.querySelector(".layout-wrapper");

    loaderWrapHeading.style.transition = "transform 0.5s ease-in-out, opacity 0.5s ease-in-out";
    svg.style.transition = "d 0.5s ease-in-out";
    loaderWrap.style.transition = "transform 1s ease-in-out";

    let curve = "M0 502S175 272 500 272s500 230 500 230V0H0Z";
    /* let flat = "M0 2S175 1 500 1s500 1 500 1V0H0Z"; */

    let wait = (timeout) => new Promise(resolve => setTimeout(resolve, timeout));

    layoutWrapper.style.transform = "translateY(200px)";
    layoutWrapper.style.opacity = 0;
    await wait(1000);
    loaderWrapHeading.style.transform = "translateY(-100px)";
    loaderWrapHeading.style.opacity = 0;
    loaderWrap.style.background = "transparent";
    svg.setAttribute("d", curve);

    await wait(500);

    /* svg.setAttribute("d", flat); */

    loaderWrap.style.transform = "translateY(-1500px)";

    wait(500).then(() => {
        loaderWrap.style.transition = "none";
        loaderWrap.style.zIndex = "-1";
        loaderWrap.style.display = "none";
    });

    layoutWrapper.style.transform = "translateY(0px)";
    layoutWrapper.style.opacity = 1;
    layoutWrapper.style.transition = "transform 0.5s ease-in-out, opacity 0.5s ease-in-out";

    layoutWrapper.addEventListener("transitionend", () => {
        layoutWrapper.removeAttribute("style");
    })
}

const html = document.querySelector('html');

function Layout() {

    const [themeMode, setThemeMode] = useState('light');
    const lightTheme = () => setThemeMode("light");
    const darkTheme = () => setThemeMode("dark");

    useEffect(() => {
        html.classList.remove('dark', 'light');
        html.classList.add(themeMode);
    }, [themeMode])

    window.addEventListener('load', () => {
        loaderHandler().then(() => {
            console.log("Dom loaded");
        });
    })

    setTimeout(() => {
        loaderHandler().then(() => {
            console.log("Dom loaded");
        });
    }, 2000)

    return (
        <GlobalContextProvider value={{ themeMode, lightTheme, darkTheme }}>
            <Scrollpage><main className={"layout-wrapper max-w-[1440px] mx-auto px-3 sm:px-12"}>

                <Navbar />
                <Outlet /><ScrollToTop />
                <Footer />
            </main></Scrollpage>
            <Preloader />
        </GlobalContextProvider>
    )
}

export default Layout;
