import React from 'react'

export default function Phone(props) {
    return (
        <svg className={props.className} width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.0016 23.9702C19.8512 23.9702 19.6998 23.9652 19.5459 23.9559C18.6957 23.9062 17.4554 23.7278 16.3742 23.1579C11.3666 20.5241 7.74619 16.7236 5.61367 11.8625C4.6725 9.71524 4.81595 7.69926 6.03003 6.03176C6.6362 5.20011 7.61411 4.58058 8.2955 4.18917C9.12559 3.71527 9.86909 4.01661 10.3414 5.03513C10.819 6.06543 11.3053 6.96525 11.8267 7.7868C12.8842 9.45261 12.6498 10.5157 10.9931 11.5763C10.7718 11.7169 10.5409 11.8449 10.2679 11.9947L10.1464 12.062C11.308 15.3878 13.4492 17.6319 16.6804 18.9071C16.7495 18.8196 16.8168 18.7337 16.8833 18.6479C17.1711 18.2792 17.4431 17.929 17.7694 17.61C18.7744 16.6277 19.6552 16.5368 20.9332 17.3516C21.8822 17.9585 22.8837 18.5317 23.8529 19.0864L24.2369 19.3069C24.7154 19.5813 25.4099 20.2219 24.6891 21.429C23.6972 23.0914 22.0773 23.9694 20.0016 23.9702ZM9.03462 4.81122C8.95852 4.81122 8.85793 4.84573 8.74335 4.91139C8.13106 5.26408 7.25636 5.81458 6.74553 6.51492C5.44223 8.30615 5.84897 10.2338 6.42014 11.5351C8.47394 16.2169 11.964 19.8785 16.795 22.4197C17.528 22.8061 18.5242 23.0527 19.5992 23.1158C21.5516 23.2328 23.0289 22.5207 23.9299 21.0098C24.2658 20.4467 24.1398 20.2312 23.7891 20.03L23.4059 19.8095C22.428 19.2497 21.4169 18.6706 20.4495 18.0536C20.0261 17.7834 19.6981 17.6571 19.4147 17.6571C19.2144 17.6571 18.8899 17.7127 18.3922 18.2C18.107 18.4795 17.8525 18.8069 17.5822 19.1529C17.4492 19.3238 17.3119 19.4989 17.1658 19.6765C17.0477 19.8204 16.8483 19.8743 16.6673 19.8078C12.8973 18.4189 10.456 15.8617 9.20256 11.993C9.14221 11.807 9.22356 11.605 9.39937 11.5056L9.83322 11.2641C10.0878 11.1243 10.3029 11.0073 10.5076 10.8752C11.7812 10.0595 11.8984 9.51574 11.0797 8.22535C10.5382 7.37266 10.0353 6.44169 9.54107 5.37856C9.42211 5.1193 9.23668 4.81122 9.03462 4.81122Z" fill="currentColor"/>
        </svg>

    )
}
