import React, { Fragment } from "react";
import Card from "./Card";
import Icon from "../../../assets/icons/Icon";
import { useNavigate } from "react-router-dom";
export default function WhyWe() {
  const navigate = useNavigate();
  const cls =
    "h-max text-center flex flex-col gap-8 px-6 py-14 no-animation bg-white rounded-2xl h-full border";
  const icon = (
    <div className={"flex justify-center items-center w-full "}>
      <div
        className={
          "w-20 aspect-square bg-black rounded-full text-white flex justify-center items-center "
        }
      >
        <Icon name={"tick"} />
      </div>
    </div>
  );

  const randStr = () => Math.random().toString(32).substr(2);
  const cardDetails = [
    {
      image: icon,
      heading: "Awarded",
      className: cls,
      description:
        "Recognized for excellence, we proudly display accolades that reflect our commitment to outstanding performance",
    },
    {
      image: icon,
      heading: "Dedicated",
      className: cls,
      description:
        "Our team is dedicated to exceeding expectations, ensuring unwavering commitment to your success.",
    },
    {
      image: icon,
      heading: "Professional",
      className: cls,
      description:
        "With a professional ethos, we deliver top-tier services backed by expertise and reliability.",
    },
    {
      image: icon,
      heading: "Global",
      className: cls,
      description:
        "Operating on a global scale, our reach extends far and wide to serve clients with a truly international perspective.",
    },
    {
      image: icon,
      heading: "Innovative",
      className: cls,
      description:
        "At the forefront of innovation, we consistently pioneer new solutions to keep you ahead in a dynamic market.",
    },
    {
      image: icon,
      heading: "Creative",
      className: cls,
      description:
        "Infusing creativity into every project, our solutions are tailored to leave a lasting and impactful impression.",
    },
    {
      image: icon,
      heading: "Supported",
      className: cls,
      description:
        "Your success is our priority, and our unwavering support ensures you thrive at every step of your journey.",
    },
    {
      image: icon,
      heading: "Focussed",
      className: cls,
      description:
        "With a laser-focused approach, we channel our efforts to meet and exceed your specific business goals.",
    },
  ];
  return (
    <div className="flex flex-col lg:flex-row gap-20 lg:gap-3 justify-between animate-700">
      <div className="flex flex-col gap-6 lg:gap-10 text-center lg:text-left lg:w-96 animate-700 lg:pb-1 h-max lg:sticky lg:top-10">
        <h2 className="heading capitalize font-semibold">
          There’s a reason why we are crashing it on global martket.
        </h2>
        <div className="para-lg">
          Our success stems from innovative strategies and a commitment to
          excellence, setting us apart in the market.
        </div>
        <div>
          <button
            className="btn btn-primary btn-lg"
            onClick={() => navigate("/calendar")}
          >
            <span>Talk to sales</span>
          </button>
        </div>
      </div>
      <div className={"grid sm:grid-cols-2 lg:w-[45rem] gap-5"}>
        <div className="flex flex-1 flex-wrap gap-5 lg:mb-20">
          {cardDetails
            .filter((_, i) => i % 2 === 0)
            .map((details, ind) => (
              <Fragment key={randStr() + ind}>
                <Card {...details} />
              </Fragment>
            ))}
        </div>
        <div className="flex flex-1 flex-wrap gap-5 lg:mt-20">
          {cardDetails
            .filter((_, i) => i % 2 !== 0)
            .map((details) => (
              <Fragment key={randStr()}>
                <Card {...details} />
              </Fragment>
            ))}
        </div>
      </div>
    </div>
  );
}
