import React from 'react'

export default function GraphicDesign(props) {
    return (
        <svg className={props.className} width="66" height="63" viewBox="0 0 66 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M51.4541 43.7076H35.3376C35.2043 43.7076 35.0962 43.5995 35.0962 43.4662C35.0962 43.333 35.2043 43.2249 35.3376 43.2249H51.4541C52.5917 43.2249 53.517 42.3028 53.517 41.1689V15.7581C53.517 15.6248 53.6251 15.5167 53.7583 15.5167C53.8916 15.5167 53.9997 15.6248 53.9997 15.7581V41.1689C53.9997 42.5687 52.8576 43.7076 51.4541 43.7076Z" fill="currentColor"/>
            <path d="M30.8975 43.7076H14.5427C13.1423 43.7076 12.0034 42.5687 12.0034 41.1689V15.7581C12.0034 15.6248 12.1115 15.5167 12.2448 15.5167C12.378 15.5167 12.4861 15.6248 12.4861 15.7581V41.1689C12.4861 42.3028 13.4088 43.2249 14.5427 43.2249H30.8975C31.0307 43.2249 31.1388 43.333 31.1388 43.4662C31.1388 43.5995 31.0307 43.7076 30.8975 43.7076Z" fill="currentColor"/>
            <path d="M53.7574 19.6638H12.2414C12.1081 19.6638 12 19.5557 12 19.4225V12.9444C12 11.5415 13.1414 10.4 14.5443 10.4H51.4544C52.8573 10.4 53.9987 11.5415 53.9987 12.9444V19.4225C53.9987 19.5557 53.8906 19.6638 53.7574 19.6638ZM12.4827 19.1811H53.516V12.9444C53.516 11.8077 52.5914 10.8827 51.4544 10.8827H14.5443C13.4073 10.8827 12.4827 11.8077 12.4827 12.9444V19.1811Z" fill="currentColor"/>
            <path d="M17.3938 17.5057C16.1178 17.5057 15.0801 16.4677 15.0801 15.1917C15.0801 13.9161 16.1178 12.8781 17.3938 12.8781C18.6697 12.8781 19.7074 13.9161 19.7074 15.1917C19.7074 16.4677 18.6697 17.5057 17.3938 17.5057ZM17.3938 13.3608C16.3843 13.3608 15.5628 14.1823 15.5628 15.1917C15.5628 16.2015 16.3843 17.023 17.3938 17.023C18.4032 17.023 19.2247 16.2015 19.2247 15.1917C19.2247 14.1823 18.4032 13.3608 17.3938 13.3608Z" fill="currentColor"/>
            <path d="M22.7611 17.5057C21.4851 17.5057 20.4468 16.4677 20.4468 15.1917C20.4468 13.9161 21.4851 12.8781 22.7611 12.8781C24.037 12.8781 25.0748 13.9161 25.0748 15.1917C25.0748 16.4677 24.037 17.5057 22.7611 17.5057ZM22.7611 13.3608C21.751 13.3608 20.9295 14.1823 20.9295 15.1917C20.9295 16.2015 21.751 17.023 22.7611 17.023C23.7705 17.023 24.592 16.2015 24.592 15.1917C24.592 14.1823 23.7705 13.3608 22.7611 13.3608Z" fill="currentColor"/>
            <path d="M28.1276 17.5057C26.8517 17.5057 25.814 16.4677 25.814 15.1917C25.814 13.9161 26.8517 12.8781 28.1276 12.8781C29.4036 12.8781 30.4413 13.9161 30.4413 15.1917C30.4413 16.4677 29.4036 17.5057 28.1276 17.5057ZM28.1276 13.3608C27.1182 13.3608 26.2967 14.1823 26.2967 15.1917C26.2967 16.2015 27.1182 17.023 28.1276 17.023C29.1371 17.023 29.9586 16.2015 29.9586 15.1917C29.9586 14.1823 29.1371 13.3608 28.1276 13.3608Z" fill="currentColor"/>
            <path d="M42.1994 25.2613H23.7975C23.7189 25.2613 23.6448 25.2229 23.5995 25.1582C23.5549 25.0938 23.5436 25.0114 23.5706 24.9373C23.6636 24.6821 23.7108 24.4046 23.7108 24.1123C23.7108 23.8276 23.6617 23.5479 23.5643 23.281C23.5373 23.2069 23.5486 23.1245 23.5932 23.0601C23.6385 22.9954 23.7127 22.957 23.7912 22.957H42.2057C42.2843 22.957 42.3584 22.9954 42.4037 23.0601C42.4483 23.1245 42.4596 23.2069 42.4326 23.281C42.3352 23.5479 42.2862 23.8276 42.2862 24.1123C42.2862 24.4046 42.3333 24.6821 42.4263 24.9373C42.4534 25.0114 42.442 25.0938 42.3974 25.1582C42.3522 25.2229 42.278 25.2613 42.1994 25.2613ZM24.12 24.7786H41.877C41.8279 24.5648 41.8034 24.342 41.8034 24.1123C41.8034 23.8844 41.8298 23.6594 41.882 23.4398H24.1149C24.1671 23.6594 24.1935 23.8844 24.1935 24.1123C24.1935 24.342 24.169 24.5648 24.12 24.7786Z" fill="currentColor"/>
            <path d="M44.7931 27.0957C43.5228 27.0957 42.3883 26.2934 41.9715 25.0995C41.8603 24.7934 41.8037 24.4603 41.8037 24.1124C41.8037 23.7711 41.8628 23.4357 41.9791 23.1158C42.3952 21.925 43.5266 21.1227 44.7931 21.1227C46.438 21.1227 47.7761 22.464 47.7761 24.1124C47.7761 25.7573 46.438 27.0957 44.7931 27.0957ZM44.7931 21.6054C43.7315 21.6054 42.7836 22.2776 42.4341 23.278C42.3355 23.5479 42.2864 23.8276 42.2864 24.1124C42.2864 24.404 42.3336 24.6818 42.426 24.9379C42.7767 25.9408 43.7277 26.613 44.7931 26.613C46.1715 26.613 47.2934 25.4914 47.2934 24.1124C47.2934 22.7299 46.1715 21.6054 44.7931 21.6054Z" fill="currentColor"/>
            <path d="M21.2038 27.0957C19.5589 27.0957 18.2207 25.7573 18.2207 24.1124C18.2207 22.464 19.5589 21.1227 21.2038 21.1227C22.4634 21.1227 23.5948 21.9244 24.019 23.1177C24.1347 23.4357 24.1938 23.7711 24.1938 24.1124C24.1938 24.4609 24.1366 24.794 24.0247 25.1026C23.601 26.294 22.4672 27.0957 21.2038 27.0957ZM21.2038 21.6054C19.8254 21.6054 18.7034 22.7299 18.7034 24.1124C18.7034 25.4914 19.8254 26.613 21.2038 26.613C22.2635 26.613 23.2145 25.9405 23.5702 24.9392C23.6639 24.6821 23.711 24.4046 23.711 24.1124C23.711 23.8276 23.662 23.5479 23.5646 23.2811C23.2082 22.2779 22.2597 21.6054 21.2038 21.6054Z" fill="currentColor"/>
            <path d="M30.4404 51.0131C30.3876 51.0131 30.336 50.9961 30.2933 50.9628C30.2298 50.9138 30.1946 50.8365 30.1996 50.7566L30.7528 41.8998C30.7842 41.38 30.6472 40.913 30.3662 40.5855C30.2166 40.4089 30.1343 40.1801 30.1343 39.94C30.1343 39.3875 30.5837 38.9381 31.1355 38.9381H35.0998C35.6485 38.9381 36.0948 39.3875 36.0948 39.94C36.0948 40.1845 36.0068 40.4177 35.8465 40.5956C35.5052 40.9878 35.4599 41.5183 35.4826 41.8942L36.0357 50.7566C36.0407 50.8371 36.0055 50.9144 35.942 50.9634C35.8779 51.0118 35.7937 51.025 35.7183 51.0005C35.6642 50.9823 35.6133 50.9741 35.5574 50.9741H30.6786C30.6227 50.9741 30.5711 50.9823 30.5164 51.0005C30.4919 51.0093 30.4661 51.0131 30.4404 51.0131ZM30.7 50.4914H35.5354L35.0973 43.4812L35.0011 41.9237C34.9728 41.4579 35.0338 40.7942 35.4845 40.2763C35.5674 40.1839 35.612 40.0651 35.612 39.94C35.612 39.654 35.382 39.4208 35.0998 39.4208H31.1355C30.8496 39.4208 30.617 39.654 30.617 39.94C30.617 40.0657 30.6585 40.1833 30.7339 40.2725C31.096 40.6943 31.2738 41.2832 31.2342 41.9294L30.7 50.4914Z" fill="currentColor"/>
            <path d="M35.5572 52.4934H30.6785C30.1228 52.4934 29.6709 52.0446 29.6709 51.4927C29.6709 51.0534 29.9512 50.6712 30.3686 50.5411C30.4679 50.5084 30.5703 50.4915 30.6785 50.4915H35.5572C35.6653 50.4915 35.7684 50.5084 35.8715 50.543C36.2826 50.6825 36.5579 51.0641 36.5579 51.4927C36.5579 52.0446 36.1091 52.4934 35.5572 52.4934ZM30.6785 50.9742C30.6225 50.9742 30.571 50.9823 30.5163 51.0006C30.3007 51.0678 30.1536 51.2696 30.1536 51.4927C30.1536 51.7781 30.3893 52.0106 30.6785 52.0106H35.5572C35.8426 52.0106 36.0751 51.7781 36.0751 51.4927C36.0751 51.2709 35.9312 51.0729 35.7169 51.0006C35.6641 50.9823 35.6132 50.9742 35.5572 50.9742H30.6785Z" fill="currentColor"/>
            <path d="M35.0972 39.4204H31.1266C31.0217 39.4204 30.928 39.3525 30.8966 39.2519L28.879 32.8804C28.779 32.5648 28.9047 32.233 29.2077 32.0136C30.8362 30.8282 31.9085 29.029 32.4384 27.9702C32.3674 27.8687 32.329 27.7511 32.329 27.6305C32.329 27.3511 32.5352 27.1097 32.8545 27.0154C32.9293 26.9934 33.0072 27.0076 33.0676 27.0534C33.0846 27.066 33.0996 27.0808 33.1122 27.0968C33.1248 27.0808 33.1399 27.066 33.1568 27.0534C33.2178 27.0076 33.2964 26.9944 33.3699 27.0151C33.6886 27.1094 33.8947 27.3507 33.8947 27.6305C33.8947 27.7508 33.8564 27.868 33.786 27.9699C34.3171 29.0293 35.3901 30.8301 37.0174 32.0142C37.3191 32.2336 37.4448 32.5655 37.3448 32.8804L35.3272 39.2519C35.2958 39.3525 35.2021 39.4204 35.0972 39.4204ZM31.3033 38.9377H34.9205L36.8847 32.7345C36.9356 32.573 36.7961 32.4498 36.7333 32.4046C35.2009 31.2892 34.143 29.6663 33.5428 28.5491V33.4247C33.759 33.5447 33.8947 33.7515 33.8947 33.9778C33.8947 34.3449 33.5434 34.6434 33.1122 34.6434C32.6804 34.6434 32.329 34.3449 32.329 33.9778C32.329 33.7515 32.4654 33.5447 32.6817 33.4247V28.5494C32.0827 29.666 31.0254 31.2876 29.4912 32.4039C29.4283 32.4498 29.2881 32.573 29.3391 32.7345L31.3033 38.9377ZM33.1122 33.7339C33.082 33.7723 33.0399 33.8018 32.9903 33.8163C32.8853 33.8464 32.8118 33.9131 32.8118 33.9778C32.8118 34.0639 32.94 34.1607 33.1122 34.1607C33.2838 34.1607 33.412 34.0639 33.412 33.9778C33.412 33.9131 33.3385 33.8464 33.2341 33.8163C33.1845 33.8018 33.1424 33.7723 33.1122 33.7339Z" fill="currentColor"/>
        </svg>

    )
}
