import React from 'react'

export default function Website(props) {
    return (
        <svg className={props.className} width="40" height="37" viewBox="0 0 40 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 31.1001V5.89999C2 3.91176 3.61178 2.29999 5.60001 2.29999H34.4C36.3883 2.29999 38 3.91176 38 5.89999V31.1001C38 33.0882 36.3883 34.7 34.4 34.7H5.60001C3.61178 34.7 2 33.0882 2 31.1001Z" stroke="currentColor" strokeWidth="2.5"/>
            <path d="M2 9.50012H38" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.39941 5.91764L7.4172 5.89789" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.8008 5.91764L12.8186 5.89789" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.2002 5.91764L18.218 5.89789" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.4004 16.7L16.4004 22.1H23.6004L20.6004 27.5" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}
