import React from 'react'

export default function Scrum(props) {
    return (
        <svg className={props.className} width="40" height="39" viewBox="0 0 40 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_414_7423)">
                <path d="M29.8203 32.5127H37.8973M37.8973 32.5127L33.41 28.0255M37.8973 32.5127L33.41 37" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M19.9491 2L15.4619 6.4872L19.9491 10.9743" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M17.2565 6.48706C24.691 6.48706 30.7181 12.514 30.7181 19.9486C30.7181 27.3833 24.691 33.4102 17.2565 33.4102H2" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M10.5362 8.2821C6.50673 10.6082 3.79492 14.9618 3.79492 19.9488C3.79492 22.9793 4.79631 25.7759 6.48625 28.0258" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_414_7423">
                    <rect width="39.8977" height="39" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    )
}
