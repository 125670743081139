import React, { useEffect, useRef } from 'react'
import Icon from "../icons/Icon";

export default function ScrollToTop() {
    const ref = useRef(null);
    const progressWrapperRef = useRef(null);

    useEffect(() => {
        const totalStrokeDashoffset = 310;
        function handleScroll() {
            const scrollY = window.scrollY;
            const scrollHeight = document.scrollingElement.scrollHeight;
            if (ref.current) {
                const action = scrollY > 300 ? "add" : "remove";
                progressWrapperRef.current.classList[action]("active-progress");
                const scrollProgress = Math.floor((scrollY / (scrollHeight - window.innerHeight)) * 100);
                const strokeDashoffset = ((totalStrokeDashoffset * scrollProgress) / 100);
                ref.current.style.strokeDashoffset = totalStrokeDashoffset - strokeDashoffset;
            }
        }

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [])
    return (
        <div onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} className="progress-wrap cursor-pointer mb-[60px]"
            ref={progressWrapperRef}>
            <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                <path ref={ref} d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"></path>
            </svg>

            <Icon className={"absolute text-grey-900 w-4 h-4 top-50 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"}
                name={"chevron angle"} />
        </div>
    )
}
