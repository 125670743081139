import React from 'react'

export default function Arrow(props) {
    return (
        <svg className={props.className} width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.1414 20.3991L19.2961 10.2444L19.0873 15.3884C19.0743 15.7082 19.3228 15.9583 19.6422 15.9478C19.8029 15.9427 19.9507 15.8721 20.0596 15.7632C20.1677 15.6551 20.2374 15.5087 20.2441 15.3496L20.5092 8.82478C20.5222 8.50501 20.2737 8.25486 19.9543 8.26533L13.4313 8.48244C13.1116 8.49264 12.8423 8.76088 12.8291 9.08039C12.8159 9.3999 13.0643 9.65061 13.384 9.63985L18.4941 9.46964L8.35303 19.6107C8.11849 19.8453 8.10519 20.2116 8.32271 20.4292C8.54023 20.6467 8.90686 20.6336 9.1414 20.3991Z" fill="currentColor"/>
        </svg>

    )
}
