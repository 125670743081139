import React from 'react';
import Logo from "../images/Store/Logo Circle.png";
import { Link, useLocation } from "react-router-dom";

export default function Footer() {
    // Get the current location
    const location = useLocation();

    // Function to scroll to top if the current page is '/'
    const handleLogoClick = () => {
        if (location.pathname === '/') {
            window.scrollTo({
                top: 0,
                behavior: 'smooth', // Smooth scroll to top
            });
        }
    };

    return (
        <footer className="self-stretch mb-2 flex flex-col items-center justify-start z-[3] text-left text-sm text-border-black font-small">
            <div className="self-stretch h-10" />
            <div className="py-7 px-5 sm:px-8 lg:px-10 xl:px-14 rounded-xl bg-white border mx-auto w-full flex flex-col md:flex-row flex-wrap items-center justify-between gap-5">
                <Link to="/" onClick={handleLogoClick}>
                    <img
                        className="h-10 w-10 relative shrink-0 object-contain"
                        alt="Logo"
                        src={Logo}
                    />
                </Link>
                <div className="relative capitalize w-fit text-center">
                    Copyright © 2024 <Link to="#" className={"link font-semibold"}>XortLogix</Link>. All rights reserved.
                </div>
                <div className="flex flex-row items-center justify-start gap-4">
                    <Link to="#" className={"link"}>Privacy Policy</Link>
                    <Link to="#" className={"link"}>Terms of Services</Link>
                </div>
            </div>
        </footer>
    );
}
