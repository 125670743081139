import Hamburger from './Store/Hamburger';
import GroupUser from "./Store/GroupUser";
import Scrum from "./Store/Scrum";
import PenTool from "./Store/PenTool";
import Website from "./Store/Website";
import WebDesign from "./Store/WebDesign";
import WebDevelop from "./Store/WebDevelop";
import GraphicDesign from "./Store/Graphic Design";
import Seo from "./Store/Seo";
import Automation from "./Store/Automation";
import AddsOn from "./Store/AddsOn";
import Dashboard from "./Store/Dashboard";
import ApiIntegration from "./Store/Api Integration";
import Support from "./Store/Support";
import Registration from "./Store/Registration";
import Highlevel from "./Store/Highlevel";
import Arrow from "./Store/Arrow";
import SquareGroup from "./Store/SquareGroup";
import Tick from "./Store/Tick";
import Email from "./Store/Email";
import Phone from "./Store/Phone";
import Location from "./Store/Location";
import Star from "./Store/Star";
import Quotes from "./Store/Quotes";
import Facebook from "./Store/Facebook";
import Twitter from "./Store/Twitter";
import LinkedIn from "./Store/LinkedIn";
import Instagram from "./Store/Instagram";
import ChevronAngle from "./Store/ChevronAngle";
import Whatsapp from "./Store/Whatsapp";

export default function Icon(props) {
    let component;
    switch (props.name?.toLowerCase && props.name.toLowerCase().trim()) {
        case 'hamburger':
            component = <Hamburger {...props}/>;
            break;
        case "group user":
            component = <GroupUser {...props}/>;
            break;
        case "scrum":
            component = <Scrum {...props}/>;
            break;
        case "pen tool":
            component = <PenTool {...props}/>;
            break;
        case "website":
            component = <Website {...props}/>;
            break;
        case "funnels":
            component = <WebDesign {...props}/>
            break;
        case "web design":
            component = <WebDesign {...props}/>
            break;
        case "web development":
            component = <WebDevelop {...props}/>
            break;
        case "graphic designing":
            component = <GraphicDesign {...props}/>
            break;
        case "seo":
            component = <Seo {...props}/>
            break;
        case "automation":
            component = <Automation {...props}/>
            break;
        case "ghl automation":
            component = <Automation {...props}/>
            break;
        case "add-ons":
            component = <AddsOn {...props}/>
            break;
        case "dashboard":
            component = <Dashboard {...props}/>
            break;
        case "api integration":
            component = <ApiIntegration {...props}/>
            break;
        case "support":
            component = <Support {...props}/>
            break;
        case "registration":
            component = <Registration {...props}/>
            break;
        case "highlevel":
            component = <Highlevel {...props}/>
            break;
        case "ghl":
            component = <Highlevel {...props}/>
            break;
        case "arrow":
            component = <Arrow {...props}/>
            break;
        case "square group":
            component = <SquareGroup {...props}/>
            break;
        case "tick":
            component = <Tick {...props}/>
            break;
        case "email":
            component = <Email {...props}/>
            break;
        case "phone":
            component = <Phone {...props}/>
            break;
        case "location":
            component = <Location {...props}/>
            break;
        case "star":
            component = <Star {...props}/>
            break;
        case "quotes":
            component = <Quotes {...props}/>
            break;
        case "facebook":
            component = <Facebook {...props}/>
            break;
        case "twitter":
            component = <Twitter {...props}/>
            break;
        case "linkedin":
            component = <LinkedIn {...props}/>
            break;
        case "instagram":
            component = <Instagram {...props}/>
            break;
        case "chevron angle":
            component = <ChevronAngle {...props}/>
            break;
        case "whatsapp":
            component = <Whatsapp {...props}/>
            break;
        default :
            component = <div className='font-bold text-red-500'>Name Error</div>;
    }

    return component;
}

