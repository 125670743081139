import React from "react";
import ReactDOM from "react-dom/client";

import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./Layout";
import Home from "./components/pages/home/Home";
import Team from "./components/pages/team/Team";
import Portfoliopage from "./components/pages/Portfolio/Portfoliopage";
import Calendar from "./components/pages/calendar/Calendar";
import Comingsoon from './components/pages/ComingSoon'
import "./index.css"
import Portfolio from "./components/pages/home/assets/Portfolio";
const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/team",
        element: <Team />,
      },
      {
        path: "/calendar",
        element: <Calendar />,
      },
      {
        path: "/portfolio",
        element: <Portfoliopage />,
      },
      {
        path: "/privacy&policy",
        element: <Comingsoon />,
      }, {
        path: "/TermsOfService",
        element: <Comingsoon />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
reportWebVitals();
