import React from 'react'

export default function Registration() {
    return (
        <svg width="65" height="62" viewBox="0 0 65 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M44.3024 18.6456C42.1957 18.6456 40.4814 16.9503 40.4814 14.8657C40.4814 12.7817 42.1951 11.0864 44.3024 11.0864C46.4097 11.0864 48.1234 12.7817 48.1234 14.8657C48.1234 16.9503 46.4092 18.6456 44.3024 18.6456ZM44.3024 11.6249C42.4956 11.6249 41.0258 13.0789 41.0258 14.8657C41.0258 16.6531 42.4956 18.1071 44.3024 18.1071C46.1092 18.1071 47.579 16.6531 47.579 14.8657C47.579 13.0789 46.1092 11.6249 44.3024 11.6249Z" fill="currentColor"/>
            <path d="M49.5593 34.6203L49.3084 34.1426C49.341 34.1254 52.6078 32.4436 53.842 31.7005C54.7238 31.1695 54.4478 29.4403 54.2671 28.8188C54.2557 28.7941 52.1887 23.7288 51.5365 21.4288C50.9949 19.5138 49.0846 19.1858 48.6834 19.1379C43.6773 21.5871 40.1977 19.5456 39.589 19.1406C39.1454 19.1977 37.2907 19.5467 36.7577 21.4288C36.1056 23.7288 34.0391 28.7941 34.0179 28.8452C33.8464 29.4403 33.5709 31.1695 34.4523 31.7005C35.7136 32.4598 36.9488 33.5185 37.0011 33.5632L36.644 33.9698C36.6314 33.959 35.3908 32.8954 34.1698 32.1603C32.7522 31.3073 33.473 28.7768 33.504 28.6697C33.5339 28.5927 35.5895 23.5548 36.2335 21.2834C36.9319 18.8175 39.6146 18.5956 39.6413 18.5935L39.742 18.5859L39.8237 18.6452C39.8585 18.671 43.3763 21.1687 48.5109 18.6209L48.5778 18.5876L48.6529 18.5929C48.6796 18.5946 51.3634 18.817 52.0607 21.2828C52.7047 23.5543 54.7603 28.5921 54.781 28.6428C54.8218 28.7763 55.542 31.3068 54.1245 32.1598C52.8757 32.9132 49.6943 34.5508 49.5593 34.6203Z" fill="currentColor"/>
            <path d="M49.7067 34.036H36.5518V26.0994H46.4235C46.5738 26.0994 46.6957 26.22 46.6957 26.3686C46.6957 26.5173 46.5738 26.6379 46.4235 26.6379H37.0961V33.4975H49.1623V26.6379H47.6032C47.4529 26.6379 47.331 26.5173 47.331 26.3686C47.331 26.22 47.4529 26.0994 47.6032 26.0994H49.7067V34.036Z" fill="currentColor"/>
            <path d="M48.342 51.969C46.9876 51.969 45.8857 50.8791 45.8857 49.5398V36.2508C45.8857 34.9115 46.9876 33.8215 48.342 33.8215C49.6958 33.8215 50.7977 34.9115 50.7977 36.2508V48.7492C50.7977 48.8979 50.6757 49.0185 50.5255 49.0185C50.3752 49.0185 50.2533 48.8979 50.2533 48.7492V36.2508C50.2533 35.2082 49.3959 34.3601 48.342 34.3601C47.2875 34.3601 46.4301 35.2082 46.4301 36.2508V49.5398C46.4301 50.5823 47.2875 51.4305 48.342 51.4305C49.2881 51.4305 50.1014 50.7331 50.2342 49.808C50.2555 49.6609 50.3932 49.5565 50.5418 49.5791C50.6904 49.6001 50.7939 49.7363 50.7732 49.8834C50.6022 51.0724 49.5576 51.969 48.342 51.969Z" fill="currentColor"/>
            <path d="M38.1885 51.969C36.925 51.969 35.8749 51.032 35.7459 49.7891C35.7307 49.6416 35.8396 49.5091 35.9893 49.494C36.1352 49.4805 36.2723 49.5866 36.2876 49.7347C36.3877 50.7014 37.2049 51.4305 38.1885 51.4305C39.243 51.4305 40.1004 50.5823 40.1004 49.5398V36.2508C40.1004 35.2082 39.243 34.3601 38.1885 34.3601C37.1352 34.3601 36.2778 35.2082 36.2778 36.2508V48.8273C36.2778 48.976 36.1558 49.0966 36.0056 49.0966C35.8553 49.0966 35.7334 48.976 35.7334 48.8273V36.2508C35.7334 34.9115 36.8347 33.8215 38.1885 33.8215C39.543 33.8215 40.6448 34.9115 40.6448 36.2508V49.5398C40.6442 50.8791 39.543 51.969 38.1885 51.969Z" fill="currentColor"/>
            <path d="M49.6209 31.6406L49.2474 31.2485L51.0363 29.5797L49.1729 23.258L49.6955 23.1067L51.6519 29.745L49.6209 31.6406Z" fill="currentColor"/>
            <path d="M39.7599 23.2586L39.2373 23.1077L38.2967 26.294L38.8193 26.4449L39.7599 23.2586Z" fill="currentColor"/>
            <path d="M43.2087 31.9266C42.2332 31.9266 41.4395 31.1415 41.4395 30.1765C41.4395 29.2114 42.2332 28.4263 43.2087 28.4263C43.3992 28.4263 43.5865 28.4559 43.7656 28.5151L43.5946 29.0262C43.4705 28.9852 43.341 28.9648 43.2087 28.9648C42.5331 28.9648 41.9838 29.5082 41.9838 30.1765C41.9838 30.8448 42.5331 31.3881 43.2087 31.3881C43.8843 31.3881 44.4335 30.8448 44.4335 30.1765C44.4335 29.9115 44.3486 29.66 44.188 29.4484L44.6235 29.1247C44.8554 29.4295 44.9779 29.7936 44.9779 30.1759C44.9779 31.1415 44.1842 31.9266 43.2087 31.9266Z" fill="currentColor"/>
            <path d="M21.989 17.6882C20.1169 17.6882 18.5938 16.1814 18.5938 14.3295C18.5938 12.4775 20.1169 10.9702 21.989 10.9702C23.8612 10.9702 25.3849 12.477 25.3849 14.3295C25.3849 16.182 23.8612 17.6882 21.989 17.6882ZM21.989 11.5087C20.4169 11.5087 19.1381 12.7742 19.1381 14.3295C19.1381 15.8847 20.4174 17.1497 21.989 17.1497C23.5612 17.1497 24.8405 15.8842 24.8405 14.3295C24.8405 12.7742 23.5612 11.5087 21.989 11.5087Z" fill="currentColor"/>
            <path d="M32.1577 31.4123H22.4248C22.3328 31.4123 22.2467 31.3665 22.1967 31.2901L17.5564 24.2484C17.4742 24.124 17.5101 23.9571 17.6359 23.8758C17.7605 23.7945 17.9304 23.8295 18.0126 23.9544L22.5723 30.8738H31.8136L31.5708 29.8646L23.6795 28.3169C23.5739 28.2965 23.4906 28.2162 23.4667 28.1123L22.3447 23.2021C22.3115 23.0567 22.4035 22.9129 22.55 22.8801C22.6964 22.8477 22.8423 22.9387 22.8755 23.0831L23.9583 27.8226L31.8474 29.3697C31.9519 29.3902 32.0347 29.4688 32.0592 29.5717L32.4223 31.0806C32.4419 31.1608 32.4228 31.2454 32.3711 31.31C32.3194 31.3746 32.241 31.4123 32.1577 31.4123Z" fill="currentColor"/>
            <path d="M13.7083 36.5299C13.6593 36.5299 13.6103 36.517 13.5673 36.4912C13.4938 36.447 13.4454 36.3711 13.4372 36.286C12.4225 25.675 17.79 18.0577 17.8445 17.9818C17.8924 17.9145 17.9691 17.873 18.0524 17.8687C21.059 17.719 22.2349 21.0346 22.2463 21.0686C22.2948 21.2091 22.2191 21.3615 22.0765 21.41C21.9349 21.459 21.7798 21.3831 21.7308 21.2425C21.6894 21.124 20.7161 18.4024 18.2533 18.4024C18.2402 18.4024 18.2266 18.4024 18.2136 18.4024C17.5799 19.3485 13.1607 26.338 13.9467 35.8751C15.0927 35.4249 18.7966 34.0156 21.0792 33.7022C21.1173 33.1626 21.2202 31.6865 21.3323 29.8814C21.3416 29.7333 21.4684 29.6278 21.6208 29.6294C21.7705 29.6385 21.8849 29.7662 21.8756 29.9148C21.7335 32.2024 21.6061 33.9617 21.6061 33.9617C21.5969 34.0915 21.4951 34.196 21.3644 34.21C18.8178 34.4868 13.8607 36.4895 13.8112 36.51C13.7785 36.5235 13.7431 36.5299 13.7083 36.5299Z" fill="currentColor"/>
            <path d="M33.0108 49.9507H28.8077L26.9818 40.1954L13.6348 36.5201L13.7812 36.0015L27.4549 39.7668L29.2606 49.4122H32.4664V40.4841C32.4686 40.3355 32.4653 36.9417 27.953 35.8598C23.2104 34.7225 21.2822 34.2077 21.2631 34.2023L21.4058 33.6826C21.4248 33.6875 23.347 34.2012 28.0809 35.3364C33.0299 36.5233 33.0114 40.4496 33.0103 40.4895L33.0108 49.9507Z" fill="currentColor"/>
            <path d="M21.4494 38.6769L10 37.3548V17.7415L12.5395 18.7L13.852 34.5249L13.3093 34.569L12.0251 19.0824L10.5444 18.5239V36.8755L21.5125 38.1421L21.4494 38.6769Z" fill="currentColor"/>
            <path d="M20.1895 51.5501H18.4551V38.2288H18.9995V51.0115H19.6451V38.2288H20.1895V51.5501Z" fill="currentColor"/>
            <path d="M11.5716 51.5502H10V37.1152H10.5444V51.0117H11.0272V37.1152H11.5716V51.5502Z" fill="currentColor"/>
            <path d="M12.5563 49.2308H11.2993V48.6923H12.0119V37.3479H12.5563V49.2308Z" fill="currentColor"/>
            <path d="M21.2629 48.3373H20.2471V47.7988H20.7185V38.2288H21.2629V48.3373Z" fill="currentColor"/>
            <path d="M29.1504 51.9703V49.4382H33.9905C33.9975 49.4377 34.0242 49.436 34.0607 49.436C34.5702 49.436 35.1647 49.7565 35.1647 50.659C35.1647 51.826 34.6671 51.952 34.4532 51.952L29.1504 51.9703ZM29.6948 49.9767V51.4296L34.4505 51.4097L34.4804 51.4167C34.4581 51.4081 34.6208 51.3063 34.6208 50.6585C34.6208 50.0925 34.3165 49.974 34.0612 49.974L34.0008 49.9762L29.6948 49.9767Z" fill="currentColor"/>
            <path d="M29.0165 25.6271C27.3551 25.6271 26.0039 24.2905 26.0039 22.6469C26.0039 21.0033 27.3551 19.6667 29.0165 19.6667C30.6779 19.6667 32.0291 21.0033 32.0291 22.6469C32.0291 24.2905 30.6774 25.6271 29.0165 25.6271ZM29.0165 20.0706C27.5804 20.0706 26.4122 21.2263 26.4122 22.6469C26.4122 24.0675 27.5804 25.2232 29.0165 25.2232C30.4526 25.2232 31.6208 24.0675 31.6208 22.6469C31.6208 21.2263 30.4526 20.0706 29.0165 20.0706Z" fill="currentColor"/>
            <path d="M29.3906 24.779H28.7559V21.8823H29.3906V24.779ZM29.0286 24.5097H29.119V22.1516H29.0286V24.5097Z" fill="currentColor"/>
            <path d="M29.073 21.6431C28.8498 21.6431 28.668 21.4638 28.668 21.2425C28.668 21.0211 28.8492 20.8418 29.073 20.8418C29.2967 20.8418 29.478 21.0211 29.478 21.2425C29.478 21.4638 29.2962 21.6431 29.073 21.6431ZM29.073 21.1111C29 21.1111 28.9402 21.1698 28.9402 21.2425C28.9402 21.3152 28.9995 21.3739 29.073 21.3739C29.1465 21.3739 29.2058 21.3152 29.2058 21.2425C29.2058 21.1698 29.1465 21.1111 29.073 21.1111Z" fill="currentColor"/>
        </svg>

    )
}
