import React from 'react'
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import {Autoplay} from "swiper/modules";
import Images from "../../../assets/images/Images";

export default function Hero() {
    const swiper1 = ["Home1", "Home2", "Home3", "Home1", "Home2", "Home3", "Home1", "Home2", "Home3", "Home1", "Home2", "Home3", "Home1", "Home2", "Home3"];
    const swiper2 = ["Home4", "Home5", "Home6", "Home7", "Home4", "Home5", "Home6", "Home7", "Home4", "Home5", "Home6", "Home7", "Home4", "Home5", "Home6", "Home7"];
    const randStr = () => Math.random().toString(32).substr(2);

    function scrollIntoView(sel) {
        const elm = document.querySelector("#" + sel);
        if (elm) {
            elm.scrollIntoView({behavior: 'smooth'});
        }
    }

    const heroSectionHeight = "max-h-[48rem]"
    return (
        // xl:grid xl:grid-cols-2
        <div
            className={`inline-flex flex-col gap-32 lg:flex-row lg:gap-10 relative overflow-hidden animate-700`}>
            {/* Left side of hero section  -- -- */}
            <div
                className={"flex w-full lg:w-2/5 xl:w-1/2  gap-8 sm:gap-12 flex-col justify-center items-start animate-700"}>
                <h2 className="w-full  heading-xxl text-center lg:text-left">
                    Finally
                </h2>
                <div className="para-xlg capitalize text-center lg:text-left">
                    You've discovered Xort Logix, a comprehensive software company dedicated to providing expert tech
                    support for CRMs, crafting brands, developing websites, and engineering digital solutions for
                    forward-thinking tech enterprises.
                </div>
                <div className={"w-full text-center lg:text-left"}>
                    <button className="btn btn-primary btn-lg" onClick={() => scrollIntoView('about')}>
                        <span>About us</span>
                    </button>
                </div>
            </div>

            {/* Right side of hero section  -- End -- */}
            <div className="h-full w-full lg:w-3/5 xl:w-1/2 grid sm:grid-cols-2 gap-5 swiper-hero-section relative">
                <div className={"absolute inset-0 z-20"}/>
                <div className="overlay-gradient top absolute left-0 w-full h-56 z-20 pointer-events-none"/>
                <div className="overlay-gradient bottom absolute left-0 w-full h-56 z-20 pointer-events-none"/>
                {/* Slide 1 -- -- */}
                <div>
                    <Swiper
                        direction={'vertical'}
                        spaceBetween={20}
                        slidesPerView={2}
                        loop={true}
                        speed={6000}
                        simulateTouch={false}
                        slidesPerGroup={1}
                        autoplay={{
                            delay: 0,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay]}
                        className={heroSectionHeight}
                    >
                        {swiper1.map(src => (
                            <SwiperSlide key={randStr()}>
                                <Images src={src} className={"object-cover rounded-2xl w-full h-full"}/>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                {/* Slide 2 -- -- */}
                <div className={"hidden sm:block"}>
                    <Swiper
                        direction={'vertical'}
                        spaceBetween={20}
                        slidesPerView={2}
                        slidesPerGroup={1}
                        loop={true}
                        speed={6000}
                        simulateTouch={false}
                        modules={[Autoplay]}
                        autoplay={{
                            delay: 0,
                            disableOnInteraction: false,
                            reverseDirection: true,
                        }}
                        className={heroSectionHeight}
                    >
                        {swiper2.map((src, ind) => (
                            <SwiperSlide key={ind}>
                                <Images src={src} className={"border object-cover rounded-2xl w-full h-full"}/>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

                {/* Slides -- End -- */}

            </div>
            {/* -- -- */}
        </div>
    )
}
