import React from 'react'

export default function Email(props) {
    return (
        <svg className={props.className} width="30" height="27" viewBox="0 0 30 27" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.8318 4.97021H8.17185C5.87226 4.97021 4 6.83402 4 9.12323V18.8172C4 21.1064 5.87226 22.9702 8.17185 22.9702H21.8281C24.1277 22.9702 26 21.1064 26 18.8172V9.12323C26.0036 6.83402 24.1314 4.97021 21.8318 4.97021ZM24.9172 18.8208C24.9172 20.5152 23.5338 21.8923 21.8318 21.8923H8.17185C6.46979 21.8923 5.08642 20.5152 5.08642 18.8208V9.12323C5.08642 7.42886 6.46979 6.05173 8.17185 6.05173H21.8281C23.5302 6.05173 24.9136 7.42886 24.9136 9.12323V18.8208H24.9172Z"
                fill="currentColor"/>
            <path
                d="M24.1378 7.09719L17.5699 12.1563C16.6481 12.8693 15.3559 12.8693 14.4341 12.1563L7.86621 7.09719C7.59872 6.89291 7.21189 6.93697 6.9979 7.19333C6.78803 7.45369 6.83329 7.83022 7.09667 8.03851L13.6646 13.1016C14.3559 13.6303 15.179 13.8947 16.002 13.8947C16.8251 13.8947 17.6481 13.6303 18.3353 13.1016L24.9032 8.03851C25.1707 7.83423 25.216 7.45369 25.002 7.19333C24.7921 6.93297 24.4012 6.89291 24.1378 7.09719Z"
                fill="currentColor"/>
        </svg>

    )
}
