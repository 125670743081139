import React, { useMemo, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProjectCard from "../home/assets/ProjectCard";
import Testimonial from "../home/assets/Testimonial";
import project from "../../assets/json/portfolio.json";
import { memo } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "../../assets/icons/Icon";
import Section from "../home/assets/Section";

const Portfoliopage = () => {
  const externalGap = (className = "") => (
    <div className={`w-full h-12 sm:h-[4.5rem] ${className}`} />
  );

  const externalGap2x = (className = "") => (
    <div className={`w-full h-24 sm:h-36 ${className}`} />
  );

  const spacing =
    "py-10 sm:py-14 px-5 sm:px-8 lg:px-10 xl:px-14 rounded-[2rem] animate-700";

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const initialFilter = queryParams.get("filter") || "all";

  const [filter, setFilter] = useState(initialFilter);

  const projects = useMemo(
    () =>
      project.portfolio.filter(
        (x) => filter === "all" || x.type.includes(filter)
      ),
    [filter]
  );

  const buttons = ["Custom Dashboard", "Forms", "Funnels/Websites", "Surveys"];

  useEffect(() => {
    navigate(`?filter=${filter}`);
  }, [filter, navigate]);

  return (
    <>
      <Section />
      <div
        className={`sm:bg-off-white-900 ${spacing} !px-0 sm:!px-8 sm:py-8 md:py-14`}
      >
        {externalGap()}
        <div className={"flex flex-col gap-16 justify-center relative"}>
          {/* Projects Filter Button */}
          <div className={"flex flex-wrap gap-4 md:gap-2.5 justify-center"}>
            <div className={"project-filter-btn-circle"}>
              <button
                className={`w-max btn btn-circle btn-primary-outline btn-sm ${
                  filter === "all" ? "active" : ""
                }`}
                onClick={() => setFilter("all")}
              >
                <span className={"w-max flex justify-center items-center"}>
                  <Icon name={"square group"} />
                  <span className={"hidden txt pl-2.5"}>All</span>
                </span>
              </button>
            </div>
            {buttons.map((btn, ind) => (
              <button
                key={btn + ind}
                className={`project-filter-btn btn btn-primary-outline btn-sm ${
                  filter === btn.toLowerCase() ? "active" : ""
                }`}
                onClick={() => setFilter(btn.toLowerCase())}
              >
                <span>{btn}</span>
              </button>
            ))}
          </div>
          {/* Projects Listing */}
          <motion.div
            layout
            className={`grid ${
              projects.length ? "md:grid-cols-2 lg:grid-cols-3" : ""
            } gap-5 capitalize`}
          >
            <AnimatePresence>
              {projects.map((detail) => (
                <ProjectCard
                  key={detail.id}
                  className={""}
                  image={detail.image}
                  previewLink={detail.previewLink}
                  subheading={detail.subheading}
                  heading={detail.heading}
                />
              ))}
            </AnimatePresence>
            {projects.length ? null : (
              <div className="text-center">
                No projects to display at the moment. Check back soon for
                updates!
              </div>
            )}
          </motion.div>
          {/* Projects Listing -- End */}
        </div>
      </div>
      {externalGap()}
      <div
        className={`sm:bg-off-white-900 ${spacing} !px-0 sm:!px-8 sm:py-8 md:py-14`}
      >
        <Testimonial />
      </div>
      <chat-widget
        location-id="fGEdlT60cGv0coO8AjhA"
        use-email-field="true"
        agency-name="Xort Logix"
        show-consent-checkbox="true"
      ></chat-widget>
    </>
  );
};

export default memo(Portfoliopage);
